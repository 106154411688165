.wrapper {
  height: 30px;
  margin: 0 15px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  position: relative;
}

/* Bottom border style */
.wrapper::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the border thickness as needed */
  background-color: black; /* Adjust the border color as needed */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

/* Hover effect to expand the bottom border */
.wrapper.active::before,
.wrapper:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  cursor: default;
}
