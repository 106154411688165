.header {
  z-index: 100;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px var(--LATERAL_SPACING);
  min-height: 100px;
  transition: background-color 0.2s ease-in;
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
}

.scrolledHeader {
  background-color: #fff;
  box-shadow: 2px 2px 14px #e9eaec;
}

.logo {
  text-decoration: none;
}

.navWrapper {
  background-color: #fff;
  margin-left: 40px;
  padding: 5px 10px;
  min-width: 200px;
  transition: padding 0.2s ease-in;
}
