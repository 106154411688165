.titleBanner {
  position: absolute;
  top: 0;
  max-width: 750px;
  margin: clamp(5.5rem, 18vw, 20rem) var(--LATERAL_SPACING);
}

.titleHeader {
  font-size: clamp(1rem, 4vw, 10rem);
}

.titleDescription {
  font-size: clamp(0.75rem, 2vw, 10rem);
}

.image {
  max-width: 100%;
  height: auto;
  position: relative;
  opacity: 0.7;
  margin-bottom: 100px;
}
