body {
  margin: 0;
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --PRIMARY-COLOR: #922c40;
  --SECONDARY-COLOR: #dc9750;
  --TERTIARY-COLOR: #f3eac0;
  --QUATERNARY-COLOR: #1e2640;
  --LATERAL_SPACING: clamp(15px, 6vw, 100px);
}
